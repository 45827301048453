import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RouterLink from "components/RouterLink"
import { Link } from "gatsby"
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { NavItem } from '../Topbar/components';
import PropTypes from 'prop-types';

const Footer = ({pages, colorInvert = false}) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const [activeLink, setActiveLink] = useState('');
  const linkColor = colorInvert ? 'common.white' : 'text.primary';
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);
  const hasActiveLink = (href) => href === activeLink;
  const {
    docs: docPages,
    products: productPages,
		githubs: githubPages,
  } = pages;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          
          <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
						<Box 
							marginRight={2}
						display={'flex'}
						component={Link}
						to="/"
						title="theFront"
						width={80}
						>
							<Box
								component={'img'}
								src={
								mode === 'light'
									? '/img/flomesh.png'
									: '/img/flomesh-white.png'
								}
								height={1}
								width={1}
							/>
							</Box>
							<Box style={{borderLeft:'1px solid #ececec'}} sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
								<Box  marginLeft={4}>
									<MuiLink fontWeight={400} underline="none" component={Link} to="/" color={linkColor} >
										Overview
									</MuiLink>
								</Box>
								<Box marginLeft={4}>
									<NavItem
										horizontal="bottom"
										title={'Products'}
										id={'product-pages'}
										items={productPages}
										colorInvert={colorInvert}
									/>
								</Box>
								<Box marginLeft={4}>
									<MuiLink component={Link} to="/docs" fontWeight={hasActiveLink("/docs") ? 700 : 400} underline="none" color={linkColor}>
									Docs
									</MuiLink>
								</Box>
								<Box marginLeft={4}>
									<MuiLink to="https://blog.flomesh.io" component={Link} target="_blank" underline="none" color={linkColor}>Blog</MuiLink>
								</Box>
								<Box marginLeft={4}>
									<MuiLink to="/contact" component={Link} underline="none" color={linkColor}>
										Contact us
									</MuiLink>
								</Box>
								<Box marginLeft={4}>
									<MuiLink to="/privacy" component={Link} underline="none" color={linkColor}>
										Privacy Policy
									</MuiLink>
								</Box>
								<Box marginLeft={4}>
									<MuiLink target="_blank" href="/eula.pdf" component={'a'} underline="none" color={linkColor}>
										EULA
									</MuiLink>
								</Box>
							</Box>
						</Box>
						<Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
							<Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
							<Box marginLeft={4}>
								<IconButton component={"a"} target="_blank" href="https://join.slack.com/t/flomesh-io/shared_invite/zt-16f4yv2hc-qvEgSrMATKn5LjmDAwzlbw" color="primary" aria-label="Join to slack">
									<svg t="1644994525977" viewBox="0 0 1024 1024" version="1.1"
									 xmlns="http://www.w3.org/2000/svg" p-id="15689" width="30" height="30">
									 <path d="M244.224 643.84c0 59.221333-45.098667 107.264-100.778667 107.264C87.808 751.104 42.666667 703.061333 42.666667 643.84c0-59.221333 45.141333-107.264 100.778666-107.264h100.778667v107.264zM294.613333 643.84c0-59.306667 45.141333-107.306667 100.821334-107.306667 55.637333 0 100.778667 48.042667 100.778666 107.264v268.288c0 59.264-45.141333 107.306667-100.778666 107.306667-55.68 0-100.821333-48.042667-100.821334-107.306667v-268.288z" fill="#E01E5A" p-id="15690"></path><path d="M395.392 214.613333c-55.637333 0-100.778667-48.042667-100.778667-107.306666C294.613333 48.042667 339.754667 0 395.392 0c55.68 0 100.821333 48.042667 100.821333 107.306667V214.613333H395.392zM395.392 268.245333c55.68 0 100.821333 48.085333 100.821333 107.306667 0 59.306667-45.141333 107.306667-100.821333 107.306667H143.445333C87.808 482.858667 42.666667 434.816 42.666667 375.552c0-59.221333 45.141333-107.306667 100.778666-107.306667h251.946667z" fill="#36C5F0" p-id="15691"></path><path d="M798.549333 375.552c0-59.221333 45.098667-107.306667 100.778667-107.306667 55.637333 0 100.778667 48.085333 100.778667 107.306667 0 59.306667-45.141333 107.306667-100.778667 107.306667h-100.778667V375.552zM748.16 375.552c0 59.306667-45.141333 107.306667-100.821333 107.306667-55.637333 0-100.778667-48.042667-100.778667-107.306667V107.306667C546.56 48.042667 591.701333 0 647.338667 0c55.68 0 100.821333 48.042667 100.821333 107.306667v268.245333z" fill="#2EB67D" p-id="15692"></path><path d="M647.381333 804.778667c55.637333 0 100.778667 48.042667 100.778667 107.306666 0 59.264-45.141333 107.306667-100.778667 107.306667-55.68 0-100.821333-48.042667-100.821333-107.306667v-107.306666h100.821333zM647.381333 751.104c-55.68 0-100.821333-48.042667-100.821333-107.306667 0-59.221333 45.141333-107.306667 100.821333-107.306666h251.904c55.68 0 100.778667 48.085333 100.778667 107.306666 0 59.306667-45.098667 107.306667-100.778667 107.306667h-251.904z" fill="#ECB22E" p-id="15693"></path></svg>
								</IconButton>
							</Box>
							<Box marginLeft={4}>
								<IconButton component={"a"} target="_blank" href="https://twitter.com/pipyproxy" color="primary" aria-label="Join to twitter">
									<svg t="1644994766298" className="icon" viewBox="0 0 1024 1024" version="1.1" 
									xmlns="http://www.w3.org/2000/svg" p-id="17154" width="30" height="30">
									<path d="M996.12 211.772c-27.41 40.139-60.586 74.338-99.524 102.58 0.419 5.715 0.628 14.311 0.628 25.788 0 53.242-7.782 106.353-23.346 159.333-15.565 52.986-39.201 103.845-70.903 152.58-31.707 48.735-69.47 91.84-113.279 129.306-43.813 37.474-96.638 67.37-158.477 89.693-61.84 22.323-127.951 33.491-198.335 33.491-110.943 0-212.483-29.692-304.613-89.063 14.305 1.622 30.264 2.434 47.876 2.434 92.13 0 174.226-28.247 246.284-84.738-42.974-0.84-81.467-14.043-115.478-39.62-34.01-25.57-57.358-58.222-70.042-97.94 13.519 2.04 26.018 3.063 37.495 3.063 17.612 0 35.008-2.256 52.2-6.764-45.855-9.43-83.828-32.252-113.908-68.466-30.08-36.208-45.12-78.268-45.12-126.163v-2.44c27.829 15.564 57.726 23.95 89.694 25.157-27.04-18.026-48.532-41.557-64.463-70.591-15.932-29.03-23.926-60.552-23.973-94.563 0-36.055 9.01-69.41 27.042-100.067 49.525 60.998 109.815 109.812 180.881 146.446 71.06 36.63 147.106 56.99 228.126 61.078-3.249-15.565-4.874-30.71-4.874-45.435 0-54.868 19.337-101.641 58.013-140.316s85.45-58.012 140.32-58.012c57.332 0 105.649 20.886 144.955 62.65 44.653-8.595 86.63-24.553 125.93-47.873-15.144 47.06-44.201 83.511-87.176 109.344 38.1-4.088 76.173-14.33 114.218-30.735l-0.151-0.157z" fill="#00ACED" p-id="17155"></path></svg>		
									</IconButton>
							</Box>
							<Box marginLeft={4}>
								<NavItem
									horizontal="bottom"
									title={'Githubs-Footer'}
									id={'gihub-pages'}
									items={githubPages}
									colorInvert={colorInvert}
								/>
							</Box>
						</Box>
				
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          &copy; 2024, Flomesh Team. &nbsp; &nbsp; &nbsp;
			    ICP备案/许可证号：  
					<MuiLink fontWeight={400} to="https://beian.miit.gov.cn" component={Link} target="_blank" underline="none" >
						辽ICP备2023014827号  
					</MuiLink>
			  </Typography>
			
			</Grid>
    </Grid>
  );
};

Footer.propTypes = {
  colorInvert: PropTypes.bool
};
export default Footer;
