import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "gatsby"
import RouterLink from "components/RouterLink"
import MuiLink from '@mui/material/Link';
import { NavItem } from './components';
import TopNav from 'components/TopNav';

const Topbar = ({ onSidebarOpen, pages, colorInvert = false,trigger= false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const linkColor = colorInvert ? 'common.white' : 'text.primary';

  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);
  
  const hasActiveLink = (href) => href === activeLink;
  
  const {
    docs: docPages,
    products: productPages,
    githubs: githubPages
  } = pages;

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
			height={{ xs: 60, md: 60 }}
    >
      <Box
				component={Link}
        display={'flex'}
        to="/"
        title="Flomesh"
        height={{ xs: 60, md: 60 }}
      >
        <Box
          component={'img'}
          src={
            mode === 'light' && !colorInvert
              ? '/img/flomesh.png'
              : '/img/flomesh-white.png'
          }
					style={{position:'relative',top:trigger?5:10,left:-10}}
          height={{ xs: trigger?50:60, md: trigger?50:60 }}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
				<Box >
					<MuiLink component={Link} to="/" fontWeight={hasActiveLink("/") ? 700 : 400} underline="none" color={linkColor}>
					Overview
					</MuiLink>
				</Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Products'}
            id={'product-pages'}
            items={productPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
					<MuiLink component={Link} to="/docs" fontWeight={hasActiveLink("/docs") ? 700 : 400} underline="none" color={linkColor}>
					Docs
					</MuiLink>
        </Box>
				<Box marginLeft={4}>
					<MuiLink to="https://blog.flomesh.io" component={Link} target="_blank" underline="none" color={linkColor}>Blog</MuiLink>
				</Box>
				<Box marginLeft={4}>
          <NavItem
            title={'Githubs'}
            id={'gihub-pages'}
            items={githubPages}
            colorInvert={colorInvert}
          />
				</Box>
				<Box marginLeft={4}>
					<TopNav colorInvert={colorInvert} />
				</Box>
				<Box marginLeft={4}>
					<Button
						component={Link}
						to="/pipy/download"
            variant="contained"
            color="primary"
            target="blank"
            size="large"
          >
            Download
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
				<Box>
					<TopNav colorInvert={colorInvert} />
				</Box>
        <Button
				  marginLeft={4}
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
	trigger: PropTypes.bool
};

export default Topbar;
