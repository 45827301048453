import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import MuiLink from '@mui/material/Link';
import { Link } from "gatsby"
import RouterLink from "components/RouterLink"
import TopNav from 'components/TopNav';

import NavItem from './components/NavItem';

const SidebarNav = ({ pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const linkColor = colorInvert ? 'common.white' : 'text.primary';

  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);
	
  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;

  const hasActiveLink = (href) => href === activeLink;
  const {
    docs: docPages
  } = pages;
	
  return (
    <Box>
      <Box width={1} paddingX={0} paddingY={1}>
			
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="theFront"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={
              mode === 'light'
                ? '/img/flomesh.png'
                : '/img/flomesh-white.png'
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        
        <Box >
          <MuiLink component={Link} to="/" fontWeight={hasActiveLink("/") ? 700 : 400} underline="none" color={linkColor}>
          Overview
          </MuiLink>
        </Box>
				<Box marginTop={2}>
					<NavItem
						title={'Docs'}
						id={'doc-pages'}
						items={docPages}
						colorInvert={colorInvert}
					/>
				</Box>
        <Box marginTop={2}>
        	<MuiLink component={Link} to={RouterLink("/pipy/docs/tutorial/01-hello")} underline="none" color={linkColor}>Tutorials</MuiLink>
        </Box>
        <Box marginTop={3}>
        	<MuiLink to="https://blog.flomesh.io" component={Link} target="_blank" underline="none" color={linkColor}>Blog</MuiLink>
        </Box>
        <Box marginTop={3}>
        	<MuiLink underline="none" component="a" href="https://github.com/flomesh-io/pipy" target="_blank" color={linkColor}>
        	  Github
        	</MuiLink>
        </Box>
        <Box marginTop={4}>
          <Button
            component={Link}
        	  to="/pipy/download"
						variant="contained"
						color="primary"
						target="blank"
						size="large"
						sx={{ marginRight: '10px'}}
					>
            Download
          </Button>
          <Button
            component={Link}
						underline="none"
        	  to="/contact"
						variant="outlined"
						color="primary"
						target="blank"
						size="large"
					>
            Contact us
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
  colorInvert: PropTypes.bool,
};

export default SidebarNav;
